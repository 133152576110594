<template>
  <div class="login-wrap">
    <div class="login-top">
      <div class="title-box">
        <div class="left_box">
          <img :src="logo" alt="">
          <span>{{ cName }}</span>
        </div>
        <div class="right_box" v-show="isRegister">
          <!-- 没有账号，<span @click="register">马上注册</span> -->
        </div>
      </div>
    </div>
    <div class="login-content" :style="bigBc">
      <div class="login-Mainbox">
        <div class="left-box" :style="boxBc">
          <div class="left-tilte-slogan">
            <p v-if="showSlogan" v-html="slogan"></p>
          </div>
        </div>
        <div class="right-box">
          <el-divider class="login-divider"> <span class="loginTitle">用户登录</span> </el-divider>
          <el-form :model="param" :rules="rules" ref="login" label-width="0px" class="ms-content" size='medium' @keyup.enter.native="handleSubmit">
            <el-form-item prop="userName">
              <el-input v-model="param.userName" placeholder="输入用户名" @input="errorChange" prefix-icon="el-icon-user">
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input @input="errorChange" type="password" placeholder="输入密码" v-model="param.password" prefix-icon="el-icon-key">
              </el-input>
            </el-form-item>
            <el-form-item prop="verifyCode">
              <div class="sliderCheck_box">
                <SliderCheck :successFun="handleSuccessFun" :errorFun="handleErrorFun"></SliderCheck>
              </div>
            </el-form-item>
            <div class="login-btn">
              <el-button type="primary" size="medium" :loading="isLoad" @click="handleSubmit" class="btn">登录</el-button>
            </div>
            <p class="login-tips" v-show="errorState">{{ errortext }}</p>
          </el-form>
        </div>
      </div>
    </div>
    <div class="footbox">
      <div class="info-box">
        <span> {{ support }}</span>
        <span> {{ buildUnit }}</span>
      </div>
      <p>{{ copyright }} <a href="https://beian.miit.gov.cn" target="_blank">{{icpCode}}</a></p>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { hnRule } from '@/libs/validate'
import { imgAddHost, checkImgExists } from '@/libs/util'
import SliderCheck from './components/SliderCheck.vue'
import md5 from 'js-md5'
import config from "@/config/index"
export default {
  components: { SliderCheck },
  data() {
    return {
      // 二维码接口
      apiUrl: '/Auth/Home/AppDownQrCode',
      srcURl: config.IpUrl,
      param: {
        userName: "",
        password: "",
        verifyCode: '',
        companyId: '',
        appType: 4
      },
      rules: {
        userName: hnRule(true, null, '请输入用户名'),
        password: hnRule(true, null, '请输入密码'),
        verifyCode: hnRule(true, null, '请将滑块拖动到最右边')
      },
      errorState: false,
      errortext: "",
      isLoad: false,
      verifyUrl: '',
      copyright: '',
      logo: '',
      bigBc: '',
      boxBc: '',
      support: '',
      slogan: '',
      showSlogan: true,
      isRegister: false,
      buildUnit: '',
      cName: '',
      icpCode: '蜀ICP备15018571号-3'
    }
  },
  created() {
    // res.loginBgImg
    this.getWebSiteCfg().then(res => {
      if (res) {
        this.copyright = res.copyright
        this.logo = imgAddHost(res.logo)
        this.handleImg(res.loginBgImg, 'bigBc')
        this.handleImg(res.loginBoxBgImg, 'boxBc')
        this.slogan = res.slogan || '万物互联-科技引领未来'
        this.showSlogan = res.showSlogan;
        this.support = res.support ? ("技术支持:" + res.support) : ""
        this.buildUnit = res.buildUnit ? ("建设单位:" + res.buildUnit) : ""
        this.cName = res.title;
        this.isRegister = res.register
        this.icpCode = res.icpCode || ''
        this.$set(this.param, 'companyId', res.id)
        window.document.title = res.title || ''
      }
    })
  },

  methods: {
    ...mapActions(["handleLogin", 'getUserInfo', 'getWebSiteCfg']),
    errorChange() {
      if (this.errorState) this.errorState = !this.errorState;
    },

    // 登录验证
    handleSubmit() {
      this.$refs.login.validate((valid) => {
        if (valid) {
          this.isLoad = true
          let password = md5(this.param.password) || ''
          let handleData = JSON.parse(JSON.stringify(this.param))
          let data = Object.assign(handleData, { password })
          this.handleLogin(data).then((ele) => {

            if (!ele) {
              this.isLoad = false
              this.errorState = true;
              this.errortext = ele.errmsg;
              // this.reqgetVerifycode()

            } else {
              this.getUserInfo().then(res => {

                this.isLoad = false
                this.$router.push({ path: "/home" });
              })
            }
          }).catch(err => {
          })
        } else {
          return false;
        }
      });
    },
    // 获取验证码
    reqgetVerifycode() {
      this.$api.getVerifycode({appType: 4}).then(res => {
        if (res) {
          this.$set(this.param, 'verifyKey', (res.key || ''))
          this.verifyUrl = res.image || ''
        }
      })
    },
    //  切换验证图片
    changeCode() {
      this.reqgetVerifycode()
    },
    //  处理图片
    handleImg(imgUrl, type) {
      const defaultBg= {
          bigBc: require('@/assets/imgs/login/bg.png'),
          boxBc: require('@/assets/imgs/login/decorate.png')
      }
      if(imgUrl){
          const hostUrL = imgAddHost(imgUrl)
          checkImgExists(hostUrL).then(data => {
            this[type]= { backgroundImage: 'url(' + hostUrL + ')' }
          }).catch(err => {
              this[type] = { backgroundImage: `url(${defaultBg[type]})` }
          })
      }else
          this[type] = { backgroundImage: `url(${defaultBg[type]})` }
          
      
      
    },
    register() {
      this.$router.push({
        path: '/register'
      })
    },
    // 滑块验证成功回调
    handleSuccessFun() {
      this.$set(this.param, 'verifyCode', true)
    },
    // 滑块验证失败回调
    handleErrorFun() {
    },
  }
}

</script>
<style lang="scss" scoped>
.login-wrap {
  width: 100vw;
  height: 100vh;
  background-size: 100%;
  overflow-y: auto;
  .login-top {
    width: 100%;
    height: 9vh;
    background: #fff;
    .title-box {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left_box {
        font-size: 28px;
        color: #343434;
        display: flex;
        align-items: center;
        img {
          width: 45px;
          margin-right: 10px;
        }
      }
      .right_box {
        & > span {
          color: #07c3a9;
          cursor: pointer;
        }
      }
    }
  }
  .login-content {
    height: 620px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .login-Mainbox {
      width: 1000px;
      height: 450px;
      border-radius: 12px;
      background: #fff;
      box-sizing: border-box;
      display: flex;
      .left-box {
        width: 55%;
        height: 100%;
        border-radius: 12px 0 0 12px;
        background-repeat: no-repeat;
        background-size: 503px 328px;
        background-position: 0% 100%;
        .left-tilte-slogan {
          text-align: center;
          padding: 20px 0;
          color: #07c3a9;
          font-size: 22px;
        }
        .left-tilte-slogan ::v-deep p::first-line {
          font-size: 32px;
          padding: 4px 0;
        }
      }
      .right-box {
        width: 45%;
        height: 100%;
        box-sizing: border-box;
        padding: 30px 50px;
        border-left: 1px solid #f6f6f6;
        .login-divider {
          background-color: #07c3a9;
          .loginTitle {
            font-size: 22px;
            color: #07c3a9;
            font-weight: bold;
          }
        }
      }
    }
  }
  .footbox {
    width: 100%;
    text-align: center;
    font-size: 12px;
    min-height: 80px;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 40px;
    color: #606266;
    a {
        color: #606266;
    }
    .info-box {
      font-size: 14px;
      margin-bottom: 10px;
      
    }
    .info-box > span:last-child {
      margin-left: 10px;
      padding-left: 10px;
      // border-left: 1px solid #686262;
    }
    .downqrcode {
      position: fixed;
      top: 85%;
      left: 70%;
    }
  }
}
 .el-input ::v-deep .el-input__inner{
      background-color: #fff;
      color: #606266;
      border: 1px solid #dcdfe6;
}
.ms-content {
  padding: 50px 10px;
}
.login-btn {
  text-align: center;
}
.login-btn button {
  width: 100%;
  // height: 36px;
  margin-bottom: 10px;
  font-size: 16px;
  letter-spacing: 2px;
  margin-top: 20px;
  background: #07c3a9;
}
.login-tips {
  font-size: 12px;
  line-height: 30px;
  color: red;
}
.verifyBox {
  display: flex;
  align-items: center;
}
.verifyInp {
  flex: 1;
}
.verifyUrl {
  width: 75px;
  height: 32px;
  margin-left: 20px;
  flex-shrink: 0;
}
@media screen and (max-width: 1450px) {
  .login-wrap {
    .login-top {
      .title-box {
        width: 1100px;
        font-size: 20px;
        img {
          width: 32px;
          margin-right: 5px;
        }
      }
    }
    .login-content {
      height: 450px;
      .login-Mainbox {
        width: 900px;
        height: 350px;
        .left-box {
          background-size: 453px 268px;
          .left-tilte-slogan {
            text-align: center;
            padding: 10px 0;
            font-size: 18px;
            color: #07c3a9;
          }
          .left-tilte-slogan ::v-deep p::first-line {
            font-size: 24px;
            padding: 4px 0;
          }
        }
      }
    }
  }
  .ms-content {
    padding: 20px 10px;
  }
  .login-btn button {
    margin-top: 0px;
  }
}
</style>